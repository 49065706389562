import React from "react";
import { Image } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import logo from "../../src/images/logo.webp";

export const Header = () => {
  return (
    <Navbar
      collapseOnSelect
      expand="lg"
      className="bg-body-tertiary"
      sticky="top"
    >
      <Container>
        <Navbar.Brand href="/">
          <Image src={logo} alt="Logo" height="60" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="ms-auto">
            <Nav.Link className="navbar-padding" href="/#home" smooth>
              Home
            </Nav.Link>
            <Nav.Link className="navbar-padding" href="/#about" smooth>
              About Us
            </Nav.Link>
            <Nav.Link className="navbar-padding" href="/#services" smooth>
              Services
            </Nav.Link>
            <Nav.Link className="navbar-padding" href="/#why-curific" smooth>
              Why Curific
            </Nav.Link>
            <Nav.Link className="navbar-padding" href="/#testimonials" smooth>
              Testimonials
            </Nav.Link>
            <Nav.Link className="navbar-padding" href="/#contactUs" smooth>
              Contact Us
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};
