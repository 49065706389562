import React from "react";
import Facebook from "../../src/images/facebook.svg";
import Instagram from "../../src/images/instagram.svg";
import Linkdin from "../../src/images/linkdin.svg";
import foundedBy from "../../src/images/iima.svg";
import startUp from "../../src/images/startUp.svg";
import { Image } from "react-bootstrap";
import logo from "../../src/images/footerLogo.svg";
import WhatsAppButton from "../componant/HomeCarosel/whatsupButton";

export default function Footer() {
  return (
    <>
      <div className="footer-section" id="contactUs">
        <div className="container py-2">
          <div className="row">
            <div className="col-lg-6 mx-auto image-container">
              <div className="d-flex">
                <div className="justify-content-center align-items-center p-4 text-center">
                  <h4 className="text-white footer-sub-heading text-center">
                    Founded by Alumni of IIM Ahmedabad
                  </h4>
                  <Image src={foundedBy} alt="foundedBy" />
                </div>
                <div className="justify-content-center align-items-center p-4 text-center">
                  <h4 className="text-white footer-sub-heading text-center mb-4">
                    Approved by
                  </h4>
                  <Image src={startUp} alt="startUp" className="" />
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 ">
              <Image className="footer-logo" src={logo} alt="" />
              <p className="text-white footer-text mb-4 mt-3">
                Curific brings proficiency and technical expertise for
                non-surgical pain management
              </p>
              <div className="d-flex align-items-center">
                <input
                  className="footer-input"
                  type="text"
                  placeholder=" Enter Your Email"
                  name="email"
                />
                <input
                  className="subscribe-btn text-white heading-title"
                  type="submit"
                  value="Subscribe"
                />
              </div>
              <div className="social-media">
                <ul className="nav">
                  <a
                    className=" nav-link"
                    href="https://www.linkedin.com/company/curific/"
                  >
                    <Image
                      className="footer-img"
                      src={Linkdin}
                      alt=" linkedin"
                      aria-label="Linkdin"
                    />
                  </a>

                  <a
                    className="nav-link"
                    href="https://www.facebook.com/curificcare"
                  >
                    <Image
                      className="footer-img"
                      src={Facebook}
                      alt="facebook"
                      aria-label="Facebook"
                    />
                  </a>

                  <a
                    className="nav-link"
                    href="https://www.instagram.com/curific.care/"
                  >
                    <Image
                      className="footer-img"
                      src={Instagram}
                      alt=" instagram"
                      aria-label="Instagram"
                    />
                  </a>
                </ul>
              </div>
            </div>

            <div className="col-lg-2 col-md-6 mb-4">
              <p className="text-white footer-sub-heading">Other Links</p>
              <ul className="fut-rout">
                <li>
                  <a className="text-white" href="/privacy-policy">
                    Privacy Policy
                  </a>
                </li>
                <li>
                  <a href="/terms-conditions" className="text-white">
                    Terms & Condition
                  </a>
                </li>
                <li>
                  <a href="/refund-policy" className="text-white">
                    Refund Policy
                  </a>
                </li>
              </ul>
            </div>

            <div className="col-lg-4">
              <h3 className="text-white footer-sub-heading">Contact</h3>
              <p className="text-white footer-text mt-4">
                Name : Curific Care Private Limited
              </p>
              <p className="text-white footer-text mt-4">
                Address : 403, Indraprasth Business House behind Rasranjan, Near
                Vijay Cross Rd, Navrangpura, Ahmedabad, Gujarat 380009
              </p>

              <h4 className="text-white footer-text">
                Phone : +91 - 76240 11050
              </h4>

              <h4 className=" text-white footer-text">
                {/* <a href="mailto:curific.care@gmail.com"></a> */}
                Email : curific.care@gmail.com
              </h4>
            </div>
          </div>
        </div>
      </div>
      <div className="webLink">
        <div className="col-lg-12 text-center">
          <p className="heading-title text-white link-web">
            © 2024 Curific . All Rights Reserved.
          </p>
        </div>
      </div>
      <WhatsAppButton phoneNumber={"+917624011050"} />
    </>
  );
}
